.beautifulScrollbarHidden {
  overflow: overlay;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 0px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #505050;
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffd482;
    border: none;
    border-radius: 10px;
  }
}

.beautifulScrollbarHiddenBlack {
  overflow: overlay;
  position: relative;
  border: 0;
}

.beautifulScrollbar {
  overflow: overlay;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #505050;
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffd482;
    border: none;
    border-radius: 10px;
  }
}
