@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  @apply h-full;
}
body {
  @apply h-full;
}
div#__next {
  @apply h-full;
}
main {
  @apply h-full;
}

@font-face {
  font-family: 'AldoTheApache';
  src: url('/src/assets/fonts/AldotheApache.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
