.con {
  background-color: rgba(61, 61, 61, 0.4);
  // padding: 10px;
}

.text {
  color: white;
}

.btnText {
  color: #ebba6a;
}

.eve {
  background-color: #3d3d3d;
}
