.head {
  background-color: #3d3d3d;
}

.input {
  outline: none;
  text-align: left;
}

.text {
  color: white;
}

.btn {
  background-color: #EEB034;
}