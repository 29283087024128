.beautifulScrollbar {
  overflow: overlay;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3D3D3D;
    border: none;
    border-radius: 10px;
  }
}
